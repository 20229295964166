import { Avatar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { fetchShopDetails, refreshAccessToken } from "../api/useFetchProducts";
import Cookies from "js-cookie";
import ShopList from '../shopList/ShopList'
import { useNavigate } from "react-router-dom";

export default function UserProfile() {
  const [shopData, setShopData] = useState(null);
  const [shopList,setShopList] =useState([])
  const [error, setError] = useState(null);
  const role=localStorage.getItem('userRole');
  const navigate=useNavigate();
  // const selectedShop = localStorage.getItem('selectedShop');
  // const shopDetail = selectedShop ? JSON.parse(selectedShop) : null;
  const [shopDetail, setShopDetail] = useState(
    JSON.parse(localStorage.getItem('selectedShop')) || null
  );

  useEffect(() => {
    const handleShopChange = () => {
      const updatedShop = JSON.parse(localStorage.getItem('selectedShop'));
      setShopDetail(updatedShop);
    };

    window.addEventListener('shopChange', handleShopChange);

    return () => {
      window.removeEventListener('shopChange', handleShopChange);
    };
  }, []);

  
  useEffect(() => {
    const getShopDetails = async () => {
      let token = localStorage.getItem('accessToken');
      try {
        const data = await fetchShopDetails();
        if(role==='AGENT'){
        if (data.statusCode === 200) {
          setShopList(data.results);
        } else if (data.statuscode === 401) {
          throw new Error("Unauthorized");
        }
      }else{
        if (data.statuscode === 200) {
          console.log("statuscode",data.statuscode)
          setShopData(data.results);
        } else if (data.statuscode === 401) {
          throw new Error("Unauthorized");
        }
      }
        console.log("ase", data);

      } catch (error) {
        if (error.message === "Unauthorized") {
          // Attempt to refresh the token if the response is 401
          token = await refreshAccessToken();
          if (token) {
            // Retry fetching products with the new token
            getShopDetails();
          } else {
            localStorage.removeItem("accessToken");
            localStorage.removeItem('userRole')
            localStorage.removeItem("listOrder");
            navigate("/login");
          }
        } else {
          console.error("Error fetching products:", error);
          setError("Failed to fetch products. Please try again later.");
          localStorage.removeItem("accessToken");
          localStorage.removeItem('userRole')
          localStorage.removeItem("listOrder");
          navigate("/login");
        }
        // setError(error.message);
      }
    };

    getShopDetails();
  }, []);
  console.log('shopData',shopList)
  return (
    <div className="container pt-3">
      {error && <div className="alert alert-danger">Error: {error}</div>}

      {role === 'AGENT' ? (
  <>
  {/* Top Section with Avatar and Basic Info */}
  <div className="row">
    <div className="col-3 d-none d-md-block"></div>
    <div className="col-md-6 col-sm-12">
      <div className="d-flex align-items-center justify-content-between mb-4 p-2">
        <Avatar
          // alt={shopData.name}
          sx={{ width: 50, height: 50 }}
          style={{ cursor: "pointer" }}
        />
        <div className="ms-3 flex-grow-1">
          <div className="d-flex flex-column">
            <span
              className="text-truncate fw-semibold"
              style={{
                maxWidth: "180px",
                display: "block",
                overflow: "hidden",
                whiteSpace: "nowrap",
                fontSize: "18px",
              }}
            >
              {shopDetail?.name}
            </span>
            <span
              className="text-truncate"
              style={{
                fontSize: "12px",
                color: "#666",
                maxWidth: "180px",
                display: "block",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
            {shopDetail?.shopcode}
            {/* code */}
            </span>
          </div>
        </div>
        <div className="text-end" style={{ fontSize: "13px" }}>
          <div>
            <span>REG:</span>{" "}
            <span className="fw-semibold">
              {shopDetail?.registerno}
              </span>
          </div>
          <div>
                <ShopList shopList={shopList}/>
          </div>
        </div>
      </div>
    </div>
    {/* Address and Contact Details */}
    <div className="col-3 d-none d-md-block"></div>
  </div>
  <div className="row">
    <div className="col-3 d-none d-md-block"></div>
    <div className="col-md-6 col-sm-12 mb-4">
      <span className="fw-semibold" style={{fontSize:'16px'}}>Address Details</span>
      <div className="d-flex justify-content-between" style={{ fontSize: "13px" }}>
        <div className="d-flex flex-column">
          <span className="fw-semibold">
            {shopDetail?.name}asd
            </span>
        </div>
      </div>
      <div className="d-flex justify-content-between pt-1" style={{fontSize:'13px'}}>
        <span className="text-truncate">
          {shopDetail?.address?.addressline1},{" "}
          {shopDetail?.address?.addressline2}
        </span>
      </div>
      <div className="d-flex justify-content-between" style={{fontSize:'13px'}}>
        <span className="">
        {shopDetail?.address?.city},{shopDetail?.address?.state} - {shopDetail?.address?.pincode}
        </span>
      </div>
      <div className="d-flex justify-content-between" style={{fontSize:'13px'}}>
        <span className="">Ph : 
        {shopDetail?.phone}
        </span>
      </div>
    </div>
    <div className="col-3 d-none d-md-block"></div>
  </div>
  {/* <div className="row">
    <div className="col-3 d-none d-md-block"></div>
    <div className="col-md-6 col-sm-12">
      <span className="fw-semibold" style={{fontSize:'16px'}}>Billing Info</span>
       <div className="d-flex justify-content-between" style={{ fontSize: "13px" }}>
        <div className="d-flex flex-column">
          <span className="fw-semibold">
            {shopDetail?.name}
            </span>
          <span>GST :<span className="fw-semibold">
            {shopDetail?.gstnumber}
            </span></span>
        </div>
      </div>
      <div className="d-flex justify-content-between pt-1" style={{fontSize:'13px'}}>
        <span className="text-break ">
          {shopDetail?.billing_address?.addressline1},{" "}
          {shopDetail.billing_address?.addressline2}
        </span>
      </div>
      <div className="d-flex justify-content-between" style={{fontSize:'13px'}}>
        <span className="">
        {shopDetail?.billing_address?.city},{shopDetail?.billing_address?.state} -{" "}
          {shopDetail.billing_address.pincode}
        </span>
      </div>
      <div className="d-flex justify-content-between mb-2" style={{fontSize:'13px'}}>
        <span className="">Ph :
         {shopDetail?.phone}
         </span>
      </div>
    </div>
    <div className="col-3 d-none d-md-block"></div>
  </div> */}
  <div className="row pt-21">
    <div className="col-3 d-none d-md-block"></div>
    <div className="col-md-6 col-sm-12">
     
    </div>
  </div>
</>
      ):(shopData && (
        <>
          {/* Top Section with Avatar and Basic Info */}
          <div className="row">
            <div className="col-3 d-none d-md-block"></div>
            <div className="col-md-6 col-sm-12">
              <div className="d-flex align-items-center justify-content-between mb-4 p-2">
                <Avatar
                  alt={shopData?.name}
                  sx={{ width: 50, height: 50 }}
                  style={{ cursor: "pointer" }}
                />
                <div className="ms-3 flex-grow-1">
                  <div className="d-flex flex-column">
                    <span
                      className="text-truncate fw-semibold"
                      style={{
                        maxWidth: "180px",
                        display: "block",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        fontSize: "18px",
                      }}
                    >
                      {shopData?.name}
                    </span>
                    <span
                      className="text-truncate"
                      style={{
                        fontSize: "12px",
                        color: "#666",
                        maxWidth: "180px",
                        display: "block",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                    {shopData?.shopcode}
                    </span>
                  </div>
                </div>
                <div className="text-end" style={{ fontSize: "13px" }}>
                  <div>
                    <span>REG:</span>{" "}
                    <span className="fw-semibold">{shopData?.registerno}</span>
                  </div>
                </div>
              </div>
            </div>
            {/* Address and Contact Details */}
            <div className="col-3 d-none d-md-block"></div>
          </div>
          <div className="row">
            <div className="col-3 d-none d-md-block"></div>
            <div className="col-md-6 col-sm-12 mb-4">
              <span className="fw-semibold" style={{fontSize:'16px'}}>Address Details</span>
              <div className="d-flex justify-content-between" style={{ fontSize: "13px" }}>
                <div className="d-flex flex-column">
                  <span className="fw-semibold">{shopData?.name}</span>
                </div>
              </div>
              <div className="d-flex justify-content-between pt-1" style={{fontSize:'13px'}}>
                <span className="text-truncate">
                  {shopData?.address?.addressline1},{" "}
                  {shopData?.address?.addressline2}
                </span>
              </div>
              <div className="d-flex justify-content-between" style={{fontSize:'13px'}}>
                <span className="">
                {shopData?.address?.city},{shopData?.address?.state} - {shopData?.address?.pincode}
                </span>
              </div>
              <div className="d-flex justify-content-between" style={{fontSize:'13px'}}>
                <span className="">Ph : {shopData?.phone}</span>
              </div>
            </div>
            <div className="col-3 d-none d-md-block"></div>
          </div>
          <div className="row">
            <div className="col-3 d-none d-md-block"></div>
            <div className="col-md-6 col-sm-12">
              <span className="fw-semibold" style={{fontSize:'16px'}}>Billing Info</span>
               <div className="d-flex justify-content-between" style={{ fontSize: "13px" }}>
                <div className="d-flex flex-column">
                  <span className="fw-semibold">{shopData?.name}</span>
                  <span>GST :<span className="fw-semibold">{shopData?.gstnumber}</span></span>
                </div>
              </div>
              <div className="d-flex justify-content-between pt-1" style={{fontSize:'13px'}}>
                <span className="text-break ">
                  {shopData?.billing_address?.addressline1},{" "}
                  {shopData?.billing_address?.addressline2}
                </span>
              </div>
              <div className="d-flex justify-content-between" style={{fontSize:'13px'}}>
                <span className="">
                {shopData?.billing_address?.city},{shopData?.billing_address?.state} -{" "}
                  {shopData?.billing_address?.pincode}
                </span>
              </div>
              <div className="d-flex justify-content-between mb-2" style={{fontSize:'13px'}}>
                <span className="">Ph : {shopData?.phone}</span>
              </div>
            </div>
            <div className="col-3 d-none d-md-block"></div>
          </div>
          <div className="row pt-21">
            <div className="col-3 d-none d-md-block"></div>
            <div className="col-md-6 col-sm-12">
             
            </div>
          </div>
        </>
      ))}
    </div>
  );
}
