import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightLong,
  faCartShopping,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import ProductTemp from "./ProductTemp";
import Nav from "react-bootstrap/Nav";
import Badge from "@mui/material/Badge";
import { useSelector } from "react-redux";
import CartTemp from "./cart/CartTemp";
import { checkOrder, useFetchProducts } from "../api/useFetchProducts";
import theme from "../../theme";
import ShopList from '../shopList/ShopList';
import { fetchShopDetails, refreshAccessToken } from "../api/useFetchProducts";

// Default initial products state will be empty, as the products will be fetched from API
const initialProducts = [];

export default function Home() {
  const [searchTerm, setSearchTerm] = useState(""); // Search term
  const [progress, setProgress] = useState(0); // Progress state for the loading bar1
  const cartitem = useSelector((state) => state.cartlists.cartItems);
  const { products, loading, error } = useFetchProducts();
  const [total, setTotal]=useState(0);
  useEffect(()=>{
  const totalPrice = cartitem.reduce((total, item) => {
    return total + item.saleprice * item.quantity;
  }, 0);
    setTotal(totalPrice) ;
})
const location = useLocation();
const [errors, setError] = useState(null);
const [shopData, setShopData] = useState(null);
const role = localStorage.getItem("userRole");
const [shopList,setShopList] =useState([])
const [shopDetail, setShopDetail] = useState(
  JSON.parse(localStorage.getItem('selectedShop')) || null
);
  const navigate=useNavigate();
  const [showAlert, setShowAlert] = useState(false);

  const productIds = cartitem.map(item => item._id);
  const [checkData,setCheckData]=useState([])
  const [checkProduct,setCheckedProduct]=useState('');

  const fetchOrderData = async () => {
    try {
        const data = await checkOrder(productIds);
        setCheckData(data.results)
        console.log("Order data:", data.results);
    } catch (error) {
        console.error("Error fetching order data:", error);
    }
};

useEffect(() => {
    fetchOrderData();
}, []);

useEffect(() => {
  const getShopDetails = async () => {
    let token = localStorage.getItem('accessToken');
    try {
      const data = await fetchShopDetails();
      if(role==='AGENT'){
      if (data.statusCode === 200) {
        setShopList(data.results);
      } else if (data.statuscode === 401) {
        throw new Error("Unauthorized");
      }
    }else{
      if (data.statuscode === 200) {
        console.log("statuscode",data.statuscode)
        setShopData(data.results);
      } else if (data.statuscode === 401) {
        throw new Error("Unauthorized");
      }
    }
      console.log("ase", data);

    } catch (error) {
      if (error.message === "Unauthorized") {
        // Attempt to refresh the token if the response is 401
        token = await refreshAccessToken();
        if (token) {
          // Retry fetching products with the new token
          getShopDetails();
        } else {
          localStorage.removeItem("accessToken");
          localStorage.removeItem('userRole')
          localStorage.removeItem("listOrder");
          navigate("/login");
        }
      } else {
        console.error("Error fetching products:", error);
        setError("Failed to fetch products. Please try again later.");
        localStorage.removeItem("accessToken");
        localStorage.removeItem('userRole')
        localStorage.removeItem("listOrder");
        navigate("/login");
      }
      // setError(error.message);
    }
  };

  getShopDetails();
}, []);

useEffect(() => {
  const handleShopChange = () => {
    const updatedShop = JSON.parse(localStorage.getItem('selectedShop'));
    setShopDetail(updatedShop);
  };

  window.addEventListener('shopChange', handleShopChange);

  return () => {
    window.removeEventListener('shopChange', handleShopChange);
  };
}, []);

const handlePlaceOrderClick = () => {
  if (checkData.some((product) => product.status === "DELETED")) {
    setShowAlert(true);
    console.log('Product is deleted');
  } else {
    navigate("/placeOrder");
    console.log('Navigating to place order');
  }
};

  // Function to handle progress bar increment during loading
  useEffect(() => {
    if (loading) {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            return 0;
          }
          const diff = Math.random() * 10;
          return Math.min(oldProgress + diff, 100);
        });
      }, 500);

      return () => {
        clearInterval(timer);
      };
    }
  }, [loading]);

  // Function to filter products based on search input
  const filteredProducts = products.filter((product) =>
    product.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product.code.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <div
      className="container-fluid"
      style={{
        backgroundColor: theme.palette.indigo.light,
        minHeight: "678px",
      }}
    >
      {/* Search Bar */}
      <div
        className={`row  bg-white `}
        style={{
          position: "sticky",
          top: 0,
          zIndex: 999,
          boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div className="col-md-12 my-3 d-flex justify-content-between">
          <div
            className="input-group"
            style={{ maxWidth: "100%", position: "relative" }}
          >
            <input
              type="search"
              className="form-control search-input"
              placeholder="Search products..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                border: "none",
                fontSize: "1.2rem",
                backgroundColor: "#f5f5f5",
              }}
            />
              {/* <span
                onClick={() => setSearchTerm("")}
                style={{
                  position: "absolute",
                  right: "3rem",
                  top: "55%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                  color: "#757575",
                }}
              >
                <FontAwesomeIcon icon={faTimes} className="fs-5" />
              </span>
           */}
            <span
              className="input-group-text"
              style={{ border: "none", background: "#e0e0e0" }}
            >
              <FontAwesomeIcon
                icon={faSearch}
                style={{ fontSize: "1rem", color: "#757575" }}
              />
            </span>
          </div>
          <Nav className="nav1 pt-1 px-2" title="Cart">
            <a
              className="text-white header-menu-padding text-center"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasCart"
              aria-controls="offcanvasCart"
              aria-label="Toggle navigation"
            >
              <Badge
                badgeContent={cartitem.length}
                sx={{
                  "& .MuiBadge-badge": {
                    backgroundColor: "#e53935",
                    color: "white",
                  },
                }}
              >
                <FontAwesomeIcon
                  className="fs-4 text-dark"
                  icon={faCartShopping}
                  style={{ verticalAlign: "-webkit-baseline-middle" }}
                />
              </Badge>
            </a>
          </Nav>
        </div>
      </div>

      {/* Offcanvas for Cart (right side) */}
      <div
        className="offcanvas offcanvas-end"
        data-bs-scroll="true"
        data-bs-backdrop="true" // Enable backdrop
        tabIndex="-1"
        id="offcanvasCart"
        aria-labelledby="offcanvasCartLabel"
        style={{
          maxHeight: "100%",
          overflowY: "auto",
        }}
      >
        <div className="side-nav-header-width">
          <button
            type="button"
            className="btn btn-close btn-close-white p-2 close-btn"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
          <div className="text-white text-start">
            <span className="text-white fw-semibold fs-5">
              <FontAwesomeIcon icon={faCartShopping} className="me-2" />
              Your Cart{" "}
            </span>
          </div>
        </div>
        <div style={{ minHeight: "100%" }}>
          <ul className="list-group">
            {cartitem.map((it, index) => (
              <CartTemp index={index} key={it._id} it={it} />
            ))}
          </ul>
          {cartitem.length > 0 && (
            <div
              className="sticky-bottom p-3"
              style={{
                backgroundColor: "#f5f5f5",
                boxShadow: "0 -2px 6px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div className="fs-5 fw-bold mb-2 text-start ">Check Out</div>
              <div className="d-flex justify-content-between align-items-center">
                <span
                  style={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    color: "black",
                  }}
                >
                  Cart Total
                </span>
                <span
                  className="roboto"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: "600",
                    color: theme.palette.indigo.dark,
                  }}
                >
                  ₹{total.toFixed(2)}
                  {/* ₹{totalPrice.toFixed(2)} */}
                </span>
              </div>
              <div className="py-1"  onClick={handlePlaceOrderClick}>
              {showAlert && (
                  <div className="alert alert-warning text-center mb-2" role="alert">
                    Please remove unavailable products before proceeding.
                  </div>
                )}
                  <button
                  className="btn popaddbtnhover"
                  style={{
                    width: "100%",
                    backgroundColor: theme.palette.indigo.main,
                    color: "white",
                  }}
                  disabled={checkData.some((product) => product.status === "DELETED")}
                >
                  Proceed to Order <FontAwesomeIcon icon={faArrowRightLong} />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* Error Message
      {error && <div className="alert alert-danger">{error}</div>}

      {/* Products List */}
      {role!=='AGENT' &&(
       <div className="row pt-2">
        {filteredProducts.length > 0 ? (
          filteredProducts.map((product, index) => (
            <ProductTemp index={index} key={product._id} product={product} />
          ))
        ) : (
          <div className="" style={{ paddingTop: "200px" }}>
            {loading && (
              <Box sx={{ width: "100%", mb: 2 }}>
                <LinearProgress variant="determinate" value={progress} />
              </Box>
            )}
          </div>
        )}
      </div>
      )}
      {role==='AGENT' && (
      shopDetail?
      <div className="row pt-2">
        {filteredProducts.length > 0 ? (
          filteredProducts.map((product, index) => (
            <ProductTemp index={index} key={product._id} product={product} />
          ))
        ) : (
          <div className="" style={{ paddingTop: "200px" }}>
            {loading && (
              <Box sx={{ width: "100%", mb: 2 }}>
                <LinearProgress variant="determinate" value={progress} />
              </Box>
            )}
          </div>
        )}
      </div>
      :<div className="row py-5">
        <div className="col-12 text-center">
          <div className="pb-2">You Can't Selectd Store .Please Select the Store First</div>
         <ShopList shopList={shopList}/>
         </div>
        </div>
        )}
    </div>
  );
}
