import React, { useState, useEffect } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios"; // Import axios to make API requests
import { useNavigate } from "react-router-dom";
// import { setAccessToken, setRefreshToken } from "../Utiles/CookieUtiles";
import theme from "../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong, faChevronRight } from "@fortawesome/free-solid-svg-icons";

export default function Page() {
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState(""); // Track email input
  const [password, setPassword] = useState(""); // Track password input
  const [error, setError] = useState(""); // Track error messages
  const navigate = useNavigate();


  const [width, setWidth] = useState(null);

  useEffect(() => {
    const updateWidth = () => {
      setWidth(window.innerWidth);
    };
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  if (width === null) {
    return null;
  }
  console.log('process.env.REACT_APP_BASE_URL',process.env.REACT_APP_BASE_URL)
  // Function to handle login submission
  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}auth-shop`,
        {
          username: username, // Use email as username
          password: password, // Pass password from state
        }
      );

      const accessToken = response.data.results.accessToken; // Extract accessToken from response
      const refreshToken = response.data.results.refreshToken;
      console.log('loginRes',response.data)
      // Save the accessToken in localStorage
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem('refreshToken',refreshToken);
      // setAccessToken(accessToken);
      // setRefreshToken(refreshToken);
      console.log("login",accessToken);
      navigate("/");
    } catch (error) {
      // Handle error (e.g., incorrect credentials)
      console.error("Login failed:", error);
      setError("Invalid username or password"); // Show error message to user
    }
  };

  return (
    <div
      className="container"
      style={{ height: "700px", width: width < 768 ? "95%" : "75%" }}
    >
      <div
        className="row justify-content-center"
        style={{ paddingTop: "150px" }}
      >
        <div
          className="col-md-6 col-sm-12 py-4 mb-2 rounded-3"
          style={{ backgroundColor: theme.palette.indigo.light }}
        >
          <div className="text-center pb-3">
            <span className="fw-semibold fs-5">Shop portal</span>
          </div>
          <div className="text-center">
            <span
              className="fw-semibold text-secondary"
              style={{ fontSize: "16px", color: "#9e9e9e" }}
            >
              login to your account
            </span>
          </div>
          {error && <div className="text-danger text-center">{error}</div>}{" "}
          {/* Show error if login fails */}
          <form
            onSubmit={handleLogin}
            className="d-flex flex-column align-items-center py-2"
            style={{ width: "70%", margin: "0 auto" }}
          >
            <input
              type="text"
              placeholder="Username"
              className="hoverborder"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              style={{
                width: "100%", // Make the input take the full width of its parent
                padding: "15px", // Add some padding for better appearance
                borderRadius: "10px", // Add rounded corners
                border: "none", // Light gray border
                marginBottom: "15px", // Space between inputs
              }}
            />
            <div style={{ position: "relative", width: "100%" }}>
              <input
                type={showPassword ? "text" : "password"}
                className="hoverborder"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{
                  width: "100%", // Make the input take the full width of its parent
                  padding: "15px", // Add some padding for better appearance
                  borderRadius: "10px", // Add rounded corners
                  border: "none", // Light gray border
                  marginBottom: "15px", // Space between inputs
                }}
              />
              <button
                type="button" // Button type for toggling
                onClick={() => setShowPassword((prev) => !prev)} // Toggle show password state
                style={{
                  position: "absolute",
                  right: "10px", // Position it on the right
                  top: "40%", // Center vertically
                  transform: "translateY(-50%)", // Adjust position to be perfectly centered
                  border: "none",
                  background: "none", // No background
                  cursor: "pointer", // Pointer cursor on hover
                }}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </button>
            </div>
            <button
              type="submit"
              style={{
                width: "100%", // Make the button take the full width of its parent
                padding: "10px", // Add some padding for better appearance
                borderRadius: "10px", // Add rounded corners
                border: "none", // Remove default border
                backgroundColor: theme.palette.indigo.main, // Bootstrap primary color
                color: "white", // Text color
                cursor: "pointer", // Change cursor on hover
                fontSize: "16px", // Increase font size for better readability
              }}
            >
              Sign In <FontAwesomeIcon icon={faArrowRightLong} />
            </button>
          </form>
          <div className="text-center pt-2">Login with <span className="text-primary pointer" onClick={()=>navigate('/salesLogin')}>SalesMan <FontAwesomeIcon icon={faChevronRight}/></span></div>
        </div>
      </div>
    </div>
  );
}
